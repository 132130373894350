/** @jsx jsx */
import React from "react"
import { Box, Grid, Heading, jsx } from "theme-ui"
import Img from "gatsby-image"
import { Slide, Zoom } from "react-awesome-reveal"

interface ClientSectionProps {
  imageArray: any[]
}

export default function ClientSection(props: ClientSectionProps) {
  return (
    <Box>
      <Slide triggerOnce={true} duration={500}>
        <Heading sx={{ fontSize: "6", textAlign: "center" }}>
          Our Clients
        </Heading>
      </Slide>
      <Grid
        m={3}
        p={2}
        columns={[4, 7]}
        gap={[3, 5]}
        sx={{ alignItems: "center" }}
      >
        {props.imageArray.map((image, index) => {
          return (
            <Zoom triggerOnce={true} duration={500}>
              <Box key={index}>
                <Img fluid={image} alt="client" />
              </Box>
            </Zoom>
          )
        })}
      </Grid>
    </Box>
  )
}
