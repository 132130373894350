/** @jsx jsx */
import React from "react"
import { jsx, Box } from "theme-ui"
import { JackInTheBox } from "react-awesome-reveal"

const Quote = (): JSX.Element => {
  return (
    <JackInTheBox triggerOnce={true}>
      <Box
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
        my={[3, null, null, 5]}
        p={3}
      >
        <div
          sx={{
            position: "relative",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: "center",
            fontSize: 3,
            fontStyle: "italic",

            "::before": {
              fontFamily: "Arial",
              content: '"“"',
              color: "primary",
              position: "absolute",
              left: "10px",
              top: "-10px",
              fontSize: 10,
            },
            "::after": {
              fontFamily: "Arial",
              content: '"”"',
              color: "primary",
              position: "absolute",
              right: "10px",
              top: "-10px",
              fontSize: 10,
            },
          }}
        >
          No one knows green better than we do. <br /> Green is not a skill or a
          knowhow, it’s a passion, it’s life.
        </div>
      </Box>
    </JackInTheBox>
  )
}

export default Quote
