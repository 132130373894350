import ContentSection from "./ContentSection"
import { Box, Image, Text, Grid, jsx } from "theme-ui"
import Img from "gatsby-image"
import React from "react"
import { JackInTheBox } from "react-awesome-reveal"

// images

interface TestimonialSectionProps {
  imageArray: any
}
interface TestimonialProps {
  text: string
  image: any
  author: string
}

const Testimonial = (props: TestimonialProps) => {
  const { text, image, author } = props
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ maxWidth: "120px", margin: "auto" }}>
        <Img fluid={image} alt={author} />
      </Box>
      <JackInTheBox triggerOnce={true}>
        <Text variant="default" mt={1} color="accent">
          {text}
        </Text>
        <Text variant="default" mt={2} sx={{ fontWeight: "bold" }}>
          {author}
        </Text>
      </JackInTheBox>
    </Box>
  )
}

const TestimonialSection = (props: TestimonialSectionProps) => {
  const [pulkitSharmaImage, ritikaImage, neerajMalakarImage] = props.imageArray
  return (
    <ContentSection
      heading="Here's Why Our Customers Love Us"
      body={
        <Grid gap={4} columns={[1, 3]} paddingBottom={3}>
          <Testimonial
            image={pulkitSharmaImage}
            text="I saw one of your plants in Fab India store yesterday, I feel I am under spell after that.. Its the most beautiful thing I have seen in recent times. And saw your collection here.. Absolutely enchanting… My complements to Your Green Canvas. "
            author="Pulkit Sharma"
          />
          <Testimonial
            image={ritikaImage}
            text="I always admired Your Green Canvas for their passion for this exquisite art. And as was expected, they not only gave me some beautiful pieces, but also added value by suggesting some unique shapes for the jars. It is their sheer love for nature that transforms into such beauties they create!! All the very best for all your natural endeavours!"
            author="Ritika"
          />
          <Testimonial
            image={neerajMalakarImage}
            text="Very satisfied…I just wanted to say that I had an wonderful experience with your team. They have done a wonderful job."
            author="Neeraj Malkar"
          />
        </Grid>
      }
    />
  )
}

export default TestimonialSection
