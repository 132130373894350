/** @jsx jsx */
import React from "react"
import Slider from "react-slick"
import { Box, jsx } from "theme-ui"

interface CarouselProps {
  children: React.ReactNode[]
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  appendDots: dots => {
    return <Box sx={{ position: "absolute", bottom: "10px" }}>{dots}</Box>
  },
}

const Carousel = (props: CarouselProps): JSX.Element => {
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Slider {...settings}>{props.children}</Slider>
    </Box>
  )
}

export default Carousel
