/** @jsx jsx */
import { Box, Text, Grid, Heading, jsx } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { Zoom, Slide } from "react-awesome-reveal"

interface ClientSpeakSectionProps {
  imageArray: any[]
}
interface ClientSpeakContainerProps {
  logo: any
  body: string
  gridArea: string
  author: string
}

function ClientSpeakContainer(props: ClientSpeakContainerProps) {
  return (
    <Box
      p={3}
      m={1}
      sx={{ border: "1px solid #ccc", gridArea: props.gridArea }}
    >
      <Box sx={{ marginBottom: 2 }}>
        <Img
          sx={{
            maxHeight: "90px",
            "& img": { maxHeight: "100%", objectFit: "contain !important" },
          }}
          fluid={props.logo}
          alt="client speak"
        />
      </Box>

      <Zoom triggerOnce={true} duration={500}>
        <Text
          sx={{
            borderTop: "1px solid #ccc",
            textAlign: "center",
            height: "auto",
          }}
          variant="secondary"
          mt={1}
          p={3}
        >
          {props.body}
        </Text>
      </Zoom>
      <Heading mt={3} sx={{ textAlign: "center", color: "#838686" }}>
        {props.author}
      </Heading>
    </Box>
  )
}

export default function ClientSpeakSection(props: ClientSpeakSectionProps) {
  const [mahindra, indiqube, bigBrewsky] = props.imageArray
  return (
    <Grid
      m={[1, 2]}
      p={3}
      columns={[1, null, null, null, 3]}
      sx={{
        gridTemplateRows: ["0.2fr 1fr auto auto", "0.3fr 1fr 1fr"],

        gridTemplateAreas: [
          "'heading''mahindra' 'indiqube' 'bigBrewsky'",
          null,
          null,
          null,
          "'mahindra heading heading' 'mahindra indiqube bigBrewsky' 'mahindra indiqube bigBrewsky'",
        ],
      }}
    >
      <ClientSpeakContainer
        gridArea="mahindra"
        logo={mahindra}
        body={
          "“I have worked with Your Green Canvas, Saumya and her exceptional bunch of people twice - once when we were looking to green up our new office in Tata CLiQ, and now when we were moving into our new corporate office at Mahindra First Choice Wheels. The best thing I like about Saumya is her uncomplicated approach to design. Give her a high level, single line brief, and she comes up with concepts that are creative, practical and cost efficient. The other noticeable part is the professionalism of delivery; Saumya and her team spend time at the site, painstakingly going through details and making changes that site constraints require her to without losing sight of the overall design. On both occasions, Saumya converted what would have been a dry, regular office into a thriving, welcoming space” says "
        }
        author={"Ashutosh Pandey, MD & CEO at Mahindra First Choice Wheels."}
      />
      <Box p={3} m={1} bg="highlight" sx={{ gridArea: "heading" }}>
        <Slide triggerOnce={true} duration={500} direction="down">
          <Heading pt={2} sx={{ fontSize: 6, textAlign: "center" }}>
            Client Speak
          </Heading>
        </Slide>
        <Text
          variant="secondary"
          sx={{ fontSize: 2, textAlign: "center" }}
          color="accent2"
        >
          Here’s why our customers love us
        </Text>
      </Box>

      <ClientSpeakContainer
        gridArea="indiqube"
        logo={indiqube}
        body={
          "“At Indiqube we’re all about looking for newer ways to enhance productivity of every individual client that chooses to work out of our offices. Studies have revealed, and from what we’ve observed, greenery at workplace is a huge catalyst for employee motivation. We are ecstatic to lead by example with the help of YourGreenCanvas to transform our office spaces into something both, startups and larger organisations can truly thrive in. “As we grow, we look to infuse greenery into our work spaces to give businesses the best working environment,” says "
        }
        author={"Meghna Agarwal, Co-Founder & COO, Indiqube."}
      />

      <ClientSpeakContainer
        gridArea="bigBrewsky"
        logo={bigBrewsky}
        body={
          "Elements of flora, herbage and greenery have merged seamlessly to uplift the vibe at BYG Brewski Brewing Company. YourGreenCanvas has done a fabulous job at infusing a sense of serenity at a high-energy atmosphere, and we witness it every day as we meet our guests. Kudos to the team on their creativity and approach.”says "
        }
        author={
          "Pravesh Pandey, Director, TBBBC Meghna Agarwal, Co-Founder & COO, Indiqube."
        }
      />
    </Grid>
  )
}
