/** @jsx jsx */
import Banner from "../components/Banner"
import { Text, AspectRatio, Grid, Box, Divider, Flex, jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import ContentSection from "../components/ContentSection"
import TestimonialSection from "../components/TestimonialSection"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/Layout"
import Carousel from "../components/Carousel"
import ServicesSection from "../components/ServicesSection"
import ClientSection from "../components/ClientSection"
import VideosSection from "../components/VideosSection"
import ClientSpeakSection from "../components/ClientSpeakSection"
import BlogSpeakSection from "../components/BlogSpeakSection"
import SEO from "../components/seo"
import Quote from "../components/Quote"
import { Zoom, Rotate, Fade, JackInTheBox } from "react-awesome-reveal"

interface SolutionProps {
  content: React.ReactNode
  header: string
}

const Solution = (props: SolutionProps) => {
  const { header, content } = props
  return (
    <Box>
      {content}
      <Rotate triggerOnce={true} duration={500}>
        <Text
          mt={2}
          mb={1}
          variant="caps"
          color="accent"
          sx={{
            fontSize: [1, 3],
            textAlign: "center",
          }}
        >
          {header}
        </Text>
      </Rotate>
      <Divider />
    </Box>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const solutionImage = graphql`
  fragment solutionImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const query = graphql`
  query {
    homeMobileBanner: file(relativePath: { eq: "homeMobileBanner.png" }) {
      ...fluidImage
    }
    homeDesktopBanner: file(relativePath: { eq: "homeDesktopBanner.png" }) {
      ...fluidImage
    }
    workplaceMobileBanner: file(
      relativePath: { eq: "workplaceMobileBanner.png" }
    ) {
      ...fluidImage
    }
    workplaceDesktopBanner: file(
      relativePath: { eq: "workplaceDesktopBanner.png" }
    ) {
      ...fluidImage
    }
    earthMobileBanner: file(relativePath: { eq: "earthMobileBanner.png" }) {
      ...fluidImage
    }
    earthDesktopBanner: file(relativePath: { eq: "earthDesktopBanner.png" }) {
      ...fluidImage
    }
    homeSolutions: file(relativePath: { eq: "homeSolutions.png" }) {
      ...solutionImage
    }
    officeSolutions: file(relativePath: { eq: "officeSolutions.png" }) {
      ...solutionImage
    }
    outdoorSolutions: file(relativePath: { eq: "outdoorSolutions.png" }) {
      ...solutionImage
    }
    officeInteriorSolutions: file(
      relativePath: { eq: "officeInteriorSolutions.png" }
    ) {
      ...solutionImage
    }
    officeExteriorSolutions: file(
      relativePath: { eq: "officeExteriorSolutions.png" }
    ) {
      ...solutionImage
    }
    medianPlantsSolutions: file(
      relativePath: { eq: "medianPlantsSolutions.png" }
    ) {
      ...solutionImage
    }
    avenuePlantsSolutions: file(
      relativePath: { eq: "avenuePlantsSolutions.png" }
    ) {
      ...solutionImage
    }
    homeOffice: file(relativePath: { eq: "homeOffice.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clientOneImage: file(relativePath: { eq: "hike.png" }) {
      ...fluidImage
    }
    clientTwoImage: file(relativePath: { eq: "mantri.png" }) {
      ...fluidImage
    }
    clientThreeImage: file(relativePath: { eq: "indiqube.png" }) {
      ...fluidImage
    }
    clientFourImage: file(relativePath: { eq: "tataCliq.png" }) {
      ...fluidImage
    }
    clientFiveImage: file(relativePath: { eq: "bigBrewsky.png" }) {
      ...fluidImage
    }
    clientSixImage: file(relativePath: { eq: "thePurpleTurtles.png" }) {
      ...fluidImage
    }
    clientSevenImage: file(relativePath: { eq: "graceHopper.png" }) {
      ...fluidImage
    }
    mahindra: file(relativePath: { eq: "mahindra.png" }) {
      ...fluidImage
    }
    indiqube: file(relativePath: { eq: "indiqube.png" }) {
      ...fluidImage
    }
    bigBrewsky: file(relativePath: { eq: "bigBrewsky.png" }) {
      ...fluidImage
    }
    pulkitSharmaImage: file(relativePath: { eq: "pulkitSharma.png" }) {
      ...fluidImage
    }
    ritikaImage: file(relativePath: { eq: "ritika.png" }) {
      ...fluidImage
    }
    neerajMalakarImage: file(relativePath: { eq: "neerajMalakar.png" }) {
      ...fluidImage
    }

    products: file(relativePath: { eq: "products.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    workshops: file(relativePath: { eq: "workshops.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="Home" />
      <Carousel>
        <Banner
          desktopImage={data.homeDesktopBanner.childImageSharp.fluid}
          mobileImage={data.homeMobileBanner.childImageSharp.fluid}
          heading="Make someone happy at home!"
          body={
            <Text variant="secondary">
              A successfully nurtured plant is all that it takes to turn a
              brown-thumb into a green one, a regular coffee-table into a
              green-oasis, a window-sill into a herb-garden and an empty balcony
              into a verdant zone - to relax, rejuvenate and rediscover. Or you
              may just want to give a green-gift-of-life to a friend who works
              from home. Come and be a part of this journey.
            </Text>
          }
          alt="home"
        />
        <Banner
          desktopImage={data.workplaceDesktopBanner.childImageSharp.fluid}
          mobileImage={data.workplaceMobileBanner.childImageSharp.fluid}
          heading="Make someone happy at WORKPLACE!"
          body={
            <Text variant="secondary">
              Now that the greens are our friends at home, why not introduce
              them to our formal work-spaces to enliven the mood! That workplace
              could be an office building or a tech-park, a restaurant or a
              hotel, a start-up or a government office, a clinic or an NGO -
              greens have the power of attenuating stress & improving
              concentration.
            </Text>
          }
          alt="home"
        />
        <Banner
          desktopImage={data.earthDesktopBanner.childImageSharp.fluid}
          mobileImage={data.earthMobileBanner.childImageSharp.fluid}
          heading="The happy Mother earth"
          body={
            <Text variant="secondary">
              YGC’s ethos of sustainability is entrenched deep into our purpose
              - touching lives, touching earth - by creating 10 million green
              spaces. Now we have taken this movement to our public spaces. We
              are making highways and bridges green by planting trees and
              shrubs.
            </Text>
          }
          alt="home"
        />
      </Carousel>
      <Box px={[1, null, null, null, 5]}>
        <ContentSection
          heading="Solutions for you"
          body={
            <Zoom triggerOnce={true} duration={500}>
              <Text
                variant="secondary"
                sx={{
                  fontSize: 3,
                  textAlign: "center",
                  maxWidth: "920px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: 4,
                }}
              >
                Our objective is to infuse greenery at multiple levels from a
                small corner in the house to large indoor and outdoor spaces;
                from mini gardens in our glass goblets to the grandeur of large
                plans and trees in open spaces. Our product range starts from
                small indoor terrariums as objects of visual delight on tables
                in claustrophobic spaces, to centre-pieces in living rooms and
                restaurant tables, to large object-de-art in big hallways, to
                creating foyers and office interiors that reflect from the
                orderly and manicured to the denseness of the jungles, and
                finally large garden spaces and interminable highways to create
                an attitude and an aura.
              </Text>
            </Zoom>
          }
        />
        <Grid columns={[2, null, null, 4]} gap={4} m={3}>
          <Solution
            header="Solution"
            content={
              <AspectRatio ratio={1 / 0.97} sx={{ maxWidth: "400px" }}>
                <Flex
                  bg="highlight"
                  sx={{
                    fontSize: 3,
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                  p={3}
                >
                  <Fade triggerOnce={true} duration={500}>
                    <Box>
                      <Text
                        variant="secondary"
                        sx={{ fontSize: [1, 4] }}
                        mb={2}
                        color="accent2"
                      >
                        Touching Lives,
                      </Text>
                      <Text
                        variant="secondary"
                        sx={{ fontSize: [1, 4] }}
                        mb={2}
                        color="accent2"
                      >
                        Rejuvenating Spaces
                      </Text>
                      <Text
                        variant="secondary"
                        sx={{ fontSize: [1, 4] }}
                        color="accent2"
                      >
                        Reshaping minds
                      </Text>
                    </Box>
                  </Fade>
                </Flex>
              </AspectRatio>
            }
          />
          <Solution
            header="Home & Home Office"
            content={
              <Link to="/solutions">
                <Img
                  fluid={data.homeSolutions.childImageSharp.fluid}
                  alt="Home & Home Office Solutions"
                />
              </Link>
            }
          />
          <Solution
            header="Office"
            content={
              <Link to="/solutions/office">
                <Img
                  fluid={data.officeSolutions.childImageSharp.fluid}
                  alt="Office Solutions"
                />
              </Link>
            }
          />
          <Solution
            header="Outdoors"
            content={
              <Link to="/solutions/outdoors">
                <Img
                  fluid={data.outdoorSolutions.childImageSharp.fluid}
                  alt="Outdoors Solutions"
                />
              </Link>
            }
          />
        </Grid>
        <Grid
          columns={[2, 3]}
          gap={[1, 2, 2, 3]}
          sx={{
            gridTemplateRows: ["1fr 1fr", "1fr"],
            gridTemplateColumns: ["5fr 4fr", "2fr 1fr 2fr"],
            gridAutoRows: "50%",
            fontSize: [3, null, null, 4, 5],
          }}
          my={5}
        >
          <Link to="/products" sx={{ gridRow: ["1/2", "1/3"] }}>
            <Img fluid={data.products.childImageSharp.fluid} />
          </Link>
          <Link
            to="/products"
            sx={{
              gridRow: "1/2",
              display: "flex",
            }}
          >
            <Flex
              bg="highlight2"
              p={3}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                width: "100%",
              }}
              color="white"
            >
              <JackInTheBox triggerOnce={true} duration={500}>
                <Text>Products</Text>
              </JackInTheBox>
            </Flex>
          </Link>
          <Link
            to="/workshop"
            sx={{
              gridRow: "2 / 3",
              gridColumn: "2 / 3",
              display: "flex",
            }}
          >
            <Flex
              bg="highlight3"
              p={3}
              sx={{
                gridRow: "2 / 3",
                gridColumn: "2 / 3",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                width: "100%",
              }}
              color="white"
            >
              <JackInTheBox triggerOnce={true} duration={500}>
                <Text>Workshops</Text>
              </JackInTheBox>
            </Flex>
          </Link>
          <Link to="/workshop" sx={{ gridRow: ["2/3", "1/3"] }}>
            <Img fluid={data.workshops.childImageSharp.fluid} />
          </Link>
        </Grid>
        <Box sx={{ paddingTop: "2em", marginTop: "-2em" }} id="what-we-do">
          <ContentSection
            heading="What We Do"
            body={
              <Zoom triggerOnce={true} duration={500}>
                <Box>
                  <Text
                    variant="secondary"
                    sx={{
                      fontSize: 3,
                      textAlign: "center",
                      maxWidth: "920px",
                      margin: "auto",
                    }}
                    paddingBottom={4}
                  >
                    The name “Your Green Canvas” is a direct reflection of our
                    philosophy. We believe we have been blessed with a large
                    canvas to paint on – from table-tops, to rooms, to
                    balconies, to offices, to gardens, to roads and highways –
                    and spread the contagions of happiness through our
                    creations.
                  </Text>
                </Box>
              </Zoom>
            }
          />
        </Box>
        <ServicesSection />
        <ContentSection
          heading="Home Office Created With Nature"
          body={
            <Grid columns={[1, "5fr 4fr"]} gap={4} paddingBottom={3}>
              <Img
                fluid={data.homeOffice.childImageSharp.fluid}
                alt="home office"
              />
              <Flex
                p={[2, null, null, null, 3]}
                bg="highlight2"
                sx={{
                  textAlign: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Zoom triggerOnce={true} duration={500}>
                    <Text
                      variant="secondary"
                      sx={{ fontSize: 3 }}
                      p={3}
                      color="accent2"
                    >
                      Let’s bring a little bit of outside, inside. Swap the view
                      of entangled cables of your workstation, with that of lush
                      leaves in a handmade planter. Missing the beach? How about
                      a lush palm in our jute planter to take you back to that
                      seaside room while you are on an official call. How about
                      that fern planter that doubles as a phone stand for your
                      zoom meetings. Bring life and light to your workstation
                      with indoor plants. Be with nature, be in nature.
                    </Text>
                  </Zoom>
                </Box>
              </Flex>
            </Grid>
          }
        />
        <ContentSection
          heading="Office & City Makeover Solutions"
          body={
            <Grid columns={[2, 4]} gap={4} my={4}>
              <Solution
                header="Office Interior"
                content={
                  <AspectRatio ratio={1 / 1.33} sx={{ maxWidth: "375px" }}>
                    <Img
                      fluid={data.officeInteriorSolutions.childImageSharp.fluid}
                    />
                  </AspectRatio>
                }
              />
              <Solution
                header="Office Exterior"
                content={
                  <AspectRatio ratio={1 / 1.33} sx={{ maxWidth: "375px" }}>
                    <Img
                      fluid={data.officeExteriorSolutions.childImageSharp.fluid}
                    />
                  </AspectRatio>
                }
              />
              <Solution
                header="Median Plants"
                content={
                  <AspectRatio ratio={1 / 1.33} sx={{ maxWidth: "375px" }}>
                    <Img
                      fluid={data.medianPlantsSolutions.childImageSharp.fluid}
                    />
                  </AspectRatio>
                }
              />
              <Solution
                header="Avenue Plants"
                content={
                  <AspectRatio ratio={1 / 1.33} sx={{ maxWidth: "375px" }}>
                    <Img
                      fluid={data.avenuePlantsSolutions.childImageSharp.fluid}
                    />
                  </AspectRatio>
                }
              />
            </Grid>
          }
        />
        <BlogSpeakSection />
        <Quote />
        <ClientSpeakSection
          imageArray={[
            data.mahindra.childImageSharp.fluid,
            data.indiqube.childImageSharp.fluid,
            data.bigBrewsky.childImageSharp.fluid,
          ]}
        />
        <TestimonialSection
          imageArray={[
            data.pulkitSharmaImage.childImageSharp.fluid,
            data.ritikaImage.childImageSharp.fluid,
            data.neerajMalakarImage.childImageSharp.fluid,
          ]}
        />
        <VideosSection
          videoArray={[
            {
              videoLink: "https://www.youtube.com/embed/ZSmjyq_SJSU",
              videoDescription: "The Your Green Canvas Story",
            },
            {
              videoLink: "https://www.youtube.com/embed/6UgJ9Z66sdM",
              videoDescription: "Show stopper for your balcony",
            },
            {
              videoLink: "https://www.youtube.com/embed/hX09I97GNA4",
              videoDescription:
                "Desktop Zen Garden in Collaboration with The Purple Turtles",
            },
          ]}
        />
        <ClientSection
          imageArray={[
            data.clientOneImage.childImageSharp.fluid,
            data.clientTwoImage.childImageSharp.fluid,
            data.clientThreeImage.childImageSharp.fluid,
            data.clientFourImage.childImageSharp.fluid,
            data.clientFiveImage.childImageSharp.fluid,
            data.clientSixImage.childImageSharp.fluid,
            data.clientSevenImage.childImageSharp.fluid,
          ]}
        />
      </Box>
    </Layout>
  )
}
