import { Box, Image, Grid, Text, AspectRatio, jsx } from "theme-ui"
import React from "react"

// icons
import BalconyIcon from "../../images/balcony.svg"
import LandscapingIcon from "../../images/landscaping.svg"
import TerrariumsIcon from "../../images/terrariums.svg"
import PlantsIcon from "../../images/plants.svg"
import WorkshopsIcon from "../../images/workshop.svg"
import GiftingIcon from "../../images/gift.svg"
import MaintenanceIcon from "../../images/maintenance.svg"
import EasyUpgradeIcon from "../../images/easyUpgrade.svg"

interface ServiceProps {
  image: string
  text: string
  color: string
  href: string
}

const Service = (props: ServiceProps) => {
  const { image, text, color } = props
  return (
    <Box>
      <a href={props.href} target="_blank">
        <AspectRatio
          ratio={1 / 0.8}
          sx={{
            border: "1px solid #D9D9D9",
            textAlign: "center",
          }}
          p={4}
          bg={color}
        >
          <Image
            src={image}
            alt={text}
            sx={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </AspectRatio>
      </a>
      <a href={props.href} target="_blank">
        <Text
          variant="secondary"
          sx={{ fontSize: [1, 2, null, 3], textAlign: "center" }}
          mt={2}
        >
          {text}
        </Text>
      </a>
    </Box>
  )
}

const ServicesSection = (): JSX.Element => {
  return (
    <Grid
      columns={[2, 4]}
      gap={[3, null, 4, 5]}
      p={3}
      sx={{ maxWidth: "1300px", margin: "auto" }}
    >
      <Service
        image={BalconyIcon}
        text="Balcony / Terrace Gardens"
        color={"white"}
        href="https://ygc.istaka.store/s?c=37"
      />
      <Service
        image={LandscapingIcon}
        text="Landscaping"
        color="#FBFBFB"
        href="https://ygc.istaka.store/s?c=32"
      />
      <Service
        image={TerrariumsIcon}
        text="Terrariums"
        color="white"
        href="https://ygc.istaka.store/s?c=35"
      />
      <Service
        image={PlantsIcon}
        text="Plants & Planters"
        color="#FBFBFB"
        href="https://ygc.istaka.store/s?c=34"
      />
      <Service
        image={WorkshopsIcon}
        text="Workshops"
        color="#fbfbfb"
        href="/workshop"
      />
      <Service
        image={GiftingIcon}
        text="Corporate Gifting"
        color="white"
        href="#"
      />
      <Service
        image={MaintenanceIcon}
        text="Maintenance & Support"
        color="#FBFBFB"
        href="https://ygc.istaka.store/s?c=45"
      />
      <Service
        image={EasyUpgradeIcon}
        text="Easy Upgrade"
        color="white"
        href="#"
      />
    </Grid>
  )
}

export default ServicesSection
