import React from "react"
import ContentSection from "./ContentSection"
import { Embed, Grid, Box, Text } from "theme-ui"

interface video {
  videoLink: string
  videoDescription: string
}

interface VideoSectionProps {
  videoArray: video[]
}

export default function VideosSection(props: VideoSectionProps) {
  return (
    <ContentSection
      heading="Our Videos"
      body={
        <Grid columns={[1, 2, null, 3]} gap={5} paddingBottom={3}>
          {props.videoArray.map((video, index) => {
            return (
              <Box key={index}>
                <Embed src={video.videoLink} />
                <Text
                  variant="secondary"
                  sx={{ fontWeight: "bold", fontSize: 3, textAlign: "center" }}
                  m={4}
                >
                  {video.videoDescription}
                </Text>
              </Box>
            )
          })}
        </Grid>
      }
    />
  )
}
