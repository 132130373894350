import React from "react"
import { Image, Text, Box, jsx } from "theme-ui"
import ContentSection from "../components/ContentSection"

import BlogSpeakIllustration from "../../images/blogSpeakIllustration.svg"

const BlogSpeakSection = (): JSX.Element => {
  return (
    <ContentSection
      heading="Blog Speak"
      body={
        <Box sx={{ textAlign: "center" }}>
          <Image src={BlogSpeakIllustration} alt="blog speak" />
          <Text variant="default" mt={3} color="accent">
            The world is a canvas and these women are painting it green with
            their nature-made art by admin | Jan 28, 2019 | Green homes,
            terrariums
          </Text>
          <Text variant="default" mt={3} color="accent">
            This Valentine’s Day, Discover The True Meaning Of Love by admin |
            Jan 17, 2019 | Environment, Lifestyle
          </Text>
        </Box>
      }
    />
  )
}

export default BlogSpeakSection
